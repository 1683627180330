@media screen and (max-width: 1542px) {
  #menubar ul li {
    font-size: 16px;
  }

  #menubar ul li.true-drop {
    font-size: 16px;
  }
}

@media screen and (max-width: 1440px) {
  .font-60 {
    font-size: 55px !important;
  }
  .font-50 {
    font-size: 45px !important;
}
}

@media screen and (max-width: 1400px) {
  #menubar ul li.true-drop {
    font-size: 15px;
    padding: 0 12px;
  }

  #menubar ul li {
    font-size: 15px;
  }

  .font-60 {
    font-size: 50px !important;
  }
}

@media screen and (max-width: 1280px) {
  #menubar ul li {
    font-size: 14px;
  }

  #menubar ul li.true-drop {
    font-size: 14px;
    padding: 0 10px;
  }

  .font-10 {
    font-size: 10px !important;
  }

  .font-11 {
    font-size: 11px !important;
  }

  .font-12 {
    font-size: 12px !important;
  }

  .font-14 {
    font-size: 14px !important;
  }

  .font-18 {
    font-size: 15px !important;
  }

  .font-20 {
    font-size: 16px !important;
  }

  .font-22 {
    font-size: 14px !important;
  }

  .font-24 {
    font-size: 16px !important;
  }

  .font-26 {
    font-size: 18px !important;
  }

  .font-28 {
    font-size: 20px !important;
  }

  .font-30 {
    font-size: 22px !important;
  }

  .font-32 {
    font-size: 24px !important;
  }

  .font-38 {
    font-size: 28px !important;
  }

  .font-42 {
    font-size: 34px !important;
  }

  .font-50 {
    font-size: 40px !important;
  }

  .font-60 {
    font-size: 45px !important;
  }

  .font-70 {
    font-size: 45px !important;
  }

  .font-80 {
    font-size: 50px !important;
  }

  .font-100 {
    font-size: 60px !important;
  }

  .topic {
    font-size: 30px;
  }

  .news-topic {
    font-size: 18px;
  }

  .event-topic {
    font-size: 20px;
  }
}

@media screen and (max-width: 1155px) {
  #menubar ul li {
    font-size: 12px;
  }

  #menubar ul li.true-drop {
    font-size: 12px;
    padding: 0 10px;
  }
  .font-50 {
    font-size: 30px !important;
  }
}

@media screen and (max-width: 1130px) {
  .box-index {
    margin-top: 70px;
  }

  .font-28 {
    font-size: 22px !important;
  }

  .mt-35 {
    margin-top: 0px;
  }

  .font-60 {
    font-size: 45px !important;
  }

  .font-80, .font-res-80 {
    font-size: 40px !important;
  }

  .font-100 {
    font-size: 50px !important;
  }

  .font-42 {
    font-size: 32px !important;
  }
}

@media screen and (max-width: 1024px) {
  .box-index {
    margin-top: 0px;
}
  .logo-home a img {
    width: 80px;
}
.col-md-20 {
  width:30% !important;
}
.col-md-20-b {
  width: 50% !important;
}
.py-res{
  padding: 60px 0;
}
.box-margin-top-lop-b {
  margin-top: -100px;
}
}
@media screen and (max-width: 820px) {
  .box-index {
    margin-top: 50px;
}
}
@media screen and (max-width: 768px) {}

@media screen and (max-width: 490px) {
  .gg-box-d {
    height: 80px;
    margin: 0px;
  }
  .timeline-steps .timeline-content .inner-circle:before{
    height: 0;
  }
  .h4-respon{
    font-size: 16px !important;
  }
  .res-m-o{
    margin-top: 0 !important;
  }
  .col-md-20 {
    width: 100% !important;
  }

  .btn-watch, .btn-news {
    /* border-radius:7px; */
    padding: 10px 15px;
    font-size: 15px;
  }

  .topic {
    font-size: 22px;
  }

  .sub-topic {
    font-size: 18px;
    font-weight: 700;
  }

  .navigate {
    font-size: 12px;
  }

  .news-topic,
  .event-topic {
    font-size: 18px;
  }

  .box-upcoming-event {
    padding: 20px 20px;
  }

  .box-index {
    margin-top: 55px;
  }

  .font-10 {
    font-size: 10px !important;
  }

  .font-11 {
    font-size: 11px !important;
  }

  .font-12 {
    font-size: 12px !important;
  }

  .font-14 {
    font-size: 14px !important;
  }

  .font-18 {
    font-size: 15px !important;
  }

  .font-20 {
    font-size: 16px !important;
  }

  .font-22 {
    font-size: 14px !important;
  }

  .font-24 {
    font-size: 16px !important;
  }

  .font-26 {
    font-size: 18px !important;
  }

  .font-28 {
    font-size: 20px !important;
  }

  .font-30 {
    font-size: 22px !important;
  }

  .font-32 {
    font-size: 24px !important;
  }

  .font-38 {
    font-size: 28px !important;
  }

  .font-42 {
    font-size: 34px !important;
  }

  .font-50 {
    font-size: 25px !important;
  }

  .font-60 {
    font-size: 30px !important;
  }

  .font-70 {
    font-size: 45px !important;
  }

  .font-80 {
    font-size: 30px !important;
  }

  .font-100 {
    font-size: 60px !important;
  }

  .font-res-100 {
    font-size: 40px !important;
  }

  .font-res-80 {
    font-size: 30px !important;
  }

  .font-res-12 {
    font-size: 12px !important;
  }

  .font-res-14 {
    font-size: 14px !important;
  }

  .mt-35 {
    margin-top: -15px;
  }

  .btn-about {
    font-size: 16px;
  }

  .btn-see {
    padding: 5px 0px;
    width: 100%;
    font-size: 16px;
  }

  .box-activities {
    border-radius: 30px 30px 0 0px;
  }

  .box-margin-top {
    margin-top: 15px;
  }

  .btn-register,
  .btn-vdocall,
  .btn-doc {
    font-size: 16px;
    padding: 5px 30px;
  }

  .box-decoding-list {
    margin-bottom: 30px;
    padding-bottom: 5px;
  }

  .board-01 {
    border-radius: 8px;
  }

  .board-01-01 {
    border-radius: 8px;
  }

  .w-md-75 {
    width: auto;
  }

  .w-100 {
    width: 100% !important;
  }

  .border-right {
    border-right: unset;
  }

  .btn-download {
    font-size: 10px;
    padding: 6px 10px;
  }

  .btn-download img {
    width: 15px !important;
  }

  .box-ddd {
    padding-right: 90px !important;
  }

  .text-board {
    border-radius: 0px 0 8px 8px;
  }

  .menu-about-left {
    height: auto;
    margin-top: 55px;
    padding-top: 15px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0.59%, rgba(0, 0, 0, 0) 94%);
    width: 100%;
  }

  .menu-about-left ul {
    padding: 0 5px 15px;
    display: table;
    width: 100%;
  }

  .menu-about-left ul li {
    display: table-cell;
    padding: 0 5px;
    border-bottom: unset;
  }

  .topic-res-menu {
    font-size: 22px !important;
    text-align: center;
  }

  .text-res-menu {
    font-size: 12px !important;
    padding-right: 0 !important;
  }

  .box-margin-top-lop {
    margin-top: 0px;
  }

  .btn-wedo {
    border-radius: 30px;
    padding: 7px 15px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    transition: 0.5s;
  }

  .box-archives {
    border-radius: 10px;
  }

  .position-md-absolute {
    position: relative;
    bottom: 0 !important;
  }

  .btn-res-50 {
    width: 48% !important;
  }

  ul.partner-logo li {
    padding: 0 5px 5px;
  }

  .go-see {
    padding: 10px 15px;
    font-size: 13px !important;
  }

  .res-font-14 {
    font-size: 15px !important;
  }

  .top-about-line {
    margin-top: 50px;
  }

  .box-res-white {
    background-color: rgba(255, 255, 255, 0.85);
  }

  .menuleft-margin {
    padding-top: 40px !important
  }

  .res-fii {
    top: 75px !important;
  }

  .content .table-bordered>:not(caption)>*>* {
    padding: 0 0px;
    max-width: 100% !important;
    width: 100%;
    display: block;
    margin-bottom: 30px;
  }

  .content img, .content-custom img {
    padding: 10px 3px;
  }
  .table-res,.table-res td{
    width: 100% !important;
    display: block;
  }

}