.btn-about {
  border-radius: 30px;
  background-color: #f5b320;
  font-weight: 700;
  font-size: 22px;
  transition: 0.5s;
  padding: 10px 70px;
}

.btn-about:hover {
  background-color: #f5b320;
  box-shadow: 0px 5px 15px 0px #f5b320;
  transform: translateY(-5px);
}

.btn-register {
  border-radius: 35.5px;
  background-color: #e51a3d;
  font-weight: 700;
  font-size: 22px;
  transition: 0.5s;
  padding: 5px 70px;
  color: #fff;
}

.btn-register:hover {
  background-color: #e51a3d;
  box-shadow: 0px 10px 30px -6px rgba(229, 26, 61, 0.4);
  transform: translateY(-1px);
  color: #fff;
}

.btn-blue {
  border-radius: 35.5px;
  background-color: #0090ce;
  font-weight: 700;
  font-size: 20px;
  transition: 0.5s;
  padding: 5px 70px;
  color: #fff;
}

.btn-blue:hover {
  background-color: #0090ce;
  box-shadow: 0px 10px 30px -6px rgba(0, 144, 206, 0.4);
  transform: translateY(-1px);
  color: #fff;
}

.btn-green {
  border-radius: 35.5px;
  background-color: #62b346;
  font-weight: 700;
  font-size: 20px;
  transition: 0.5s;
  padding: 5px 70px;
  color: #fff;
}

.btn-green:hover {
  background-color: #62b346;
  box-shadow: 0px 10px 30px -6px rgba(98, 179, 70, 0.4);
  transform: translateY(-1px);
  color: #fff;
}

.btn-download {
  border-radius: 6px;
  background-color: #05a2e9;
  font-weight: 700;
  font-size: 14px;
  transition: 0.5s;
  padding: 3px 25px;
  color: #fff;
  display: block;
}

.btn-see,.btn-see-lang  {
  border-radius: 8px;
  border: 1px solid #000000;
  font-weight: 700;
  font-size: 20px;
  transition: 0.5s;
  padding: 5px 60px;
}

.btn-see:hover,.btn-see-lang:hover {
  background-color: #000;
  color: #fff;
}

.btn-default {
  color: #7f7f7f !important;
  transition: 0.5s;
}

.btn-default:hover {
  color: #000 !important;
}

.btn-doc {
  border-radius: 8px;
  background-color: #f08e31;
  font-weight: 600;
  font-size: 20px;
  transition: 0.5s;
  padding: 5px 30px;
  color: #fff;
}

.btn-doc:hover {
  background-color: #f08e31;
  box-shadow: 0px 5px 15px 0px #f08e31;
  transform: translateY(-15px);
  color: #fff;
}

.btn-watch {
  border-radius: 30px;
  background-color: #f08e31;
  font-weight: 600;
  font-size: 20px;
  transition: 0.5s;
  padding: 10px 60px;
  font-size: 22px;
  color: #fff;
}
.btn-watch-passtel {
  border-radius: 30px;
  background-color: #FFD473;
  font-weight: 600;
  font-size: 20px;
  transition: 0.5s;
  padding: 10px 60px;
  font-size: 22px;
  color: #fff;
}
.btn-watch-passtel:hover{
  background-color: #FFD473;
  box-shadow: 0px 5px 15px 0px #FFD473;
  /* transform: translateY(-15px); */
  color: #fff;
}
.btn-green-passtel {
  border-radius: 30px;
  background-color: #ADDA9D;
  font-weight: 600;
  font-size: 20px;
  transition: 0.5s;
  padding: 10px 60px;
  font-size: 22px;
  color: #fff;
}
.btn-green-passtel:hover{
  background-color: #ADDA9D;
  box-shadow: 0px 5px 15px 0px #ADDA9D;
  /* transform: translateY(-15px); */
  color: #fff;
}

.btn-blue-passtel {
  border-radius: 30px;
  background-color: #8FB6EA;
  font-weight: 600;
  font-size: 20px;
  transition: 0.5s;
  padding: 10px 60px;
  font-size: 22px;
  color: #fff;
}
.btn-blue-passtel:hover{
  background-color: #8FB6EA;
  box-shadow: 0px 5px 15px 0px #8FB6EA;
  /* transform: translateY(-15px); */
  color: #fff;
}

.btn-watch:hover {
  background-color: #f08e31;
  box-shadow: 0px 5px 15px 0px #f08e31;
  /* transform: translateY(-15px); */
  color: #fff;
}

.btn-news {
  border-radius: 30px;
  background-color: #0090CE;
  font-weight: 600;
  font-size: 20px;
  transition: 0.5s;
  padding: 10px 60px;
  font-size: 22px;
  color: #fff;
}

.btn-news:hover {
  background-color: #0090CE;
  box-shadow: 0px 5px 15px 0px #0090CE;
  /* transform: translateY(-15px); */
  color: #fff;
}

.btn-vdocall {
  border-radius: 8px;
  background-color: #05a2e9;
  font-weight: 600;
  font-size: 20px;
  transition: 0.5s;
  padding: 5px 30px;
  color: #fff;
}

.btn-vdocall:hover {
  background-color: #05a2e9;
  box-shadow: 0px 5px 15px 0px #05a2e9;
  transform: translateY(-15px);
  color: #fff;
}

.rotate-180 {
  transform: rotate(180deg);
}

.btn-wedo {

  border-radius: 30px;
  padding: 10px 30px;
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  transition: 0.5s;
}

.yellow-passtel {
  background-color: #FFD473;
  animation: slow-yellow-passtel 1s linear infinite;
  /* transition: 0.5s; */

}

.yellow-passtel:hover {
  color: #fff;
  background: #FFD473;
  /* box-shadow: 0px 10px 20px 0px rgba(245, 179, 32, 0.40); */
  transition: 0.5s;
}
@keyframes slow-yellow-passtel {

  0%, 100% {
    bottom: 7px;
  }

  50% {
    bottom: 0px;
    box-shadow: 0px 12px 15px -8px rgba(245, 179, 32, 0.4)
  }
}


.peat-passtel {
  background-color: #FFB99C;
  transition: 0.5s;
  animation: slow-peat-passtel 1s linear infinite;
}

.peat-passtel:hover {
  color: #fff;
  background: #FFB99C;
  /* box-shadow: 0px 10px 20px 0px rgba(234, 103, 48, 0.40); */
  transition: 0.5s;
}
@keyframes slow-peat-passtel {

  0%, 100% {
    bottom: 7px;
  }

  50% {
    bottom: 0;
    box-shadow: 0px 12px 15px -8px rgba(234, 103, 48, 0.4)
  }
}
.sky-passtel {
  background-color: #98E4F1;
  transition: 0.5s;
  animation: slow-sky-passtel 1s linear infinite;
}

.sky-passtel:hover {
  color: #fff;
  background: #98E4F1;
  /* box-shadow: 0px 10px 20px 0px rgba(0, 184, 217, 0.40); */
  transition: 0.5s;
}
@keyframes slow-sky-passtel {

  0%, 100% {
    bottom: 7px;
  }

  50% {
    bottom: 0;
    box-shadow: 0px 12px 15px -8px rgba(0, 184, 217, 0.4)
  }
}

.green-passtel {
  background-color: #ADDA9D;
  transition: 0.5s;
  animation: slow-green-passtel 1s linear infinite;
}

.green-passtel:hover {
  color: #fff;
  background: #ADDA9D;
  /* box-shadow: 0px 10px 20px 0px rgba(98, 179, 70, 0.40); */
  transition: 0.5s;
}
@keyframes slow-green-passtel {

  0%, 100% {
    bottom: 7px;
  }

  50% {
    bottom: 0;
    box-shadow: 0px 12px 15px -8px rgba(98, 179, 70, 0.4)
  }
}

.pueple-passtel {
  background-color: #D0B7F1;
  transition: 0.5s;
  animation: slow-pueple-passtel 1s linear infinite;
}

.pueple-passtel:hover {
  color: #fff;
  background: #D0B7F1;
  /* box-shadow: 0px 10px 20px 0px rgba(151, 71, 255, 0.40); */
  transition: 0.5s;
}
@keyframes slow-pueple-passtel {

  0%, 100% {
    bottom: 7px;
  }

  50% {
    bottom: 0;
    box-shadow: 0px 12px 15px -8px rgba(151, 71, 255, 0.4)
  }
}

.red-passtel {
  background-color: #ED95A4;
  transition: 0.5s;
  animation: slow-red-passtel 1s linear infinite;
}

.red-passtel:hover {
  color: #fff;
  background: #ED95A4;
  /* box-shadow: 0px 10px 20px 0px rgba(229, 26, 61, 0.40); */
  transition: 0.5s;
}
@keyframes slow-red-passtel {

  0%, 100% {
    bottom: 7px;
  }

  50% {
    bottom: 0;
    box-shadow: 0px 12px 15px -8px rgba(229, 26, 61, 0.4)
  }
}

.blue-passtel {
  background-color: #8FB6EA;
  transition: 0.5s;
  animation: slow-blue-passtel 1s linear infinite;
}

.blue-passtel:hover {
  color: #fff;
  background: #8FB6EA;
  /* box-shadow: 0px 10px 20px 0px rgba(0, 95, 221, 0.40); */
  transition: 0.5s;
}
@keyframes slow-blue-passtel {

  0%, 100% {
    bottom: 7px;
  }

  50% {
    bottom: 0;
    box-shadow: 0px 12px 15px -8px rgba(0, 95, 221, 0.4)
  }
}


/* div.slide-slow div.inner {
  animation: slide-slow 3s;
  margin-top: 0%;
  animation-iteration-count: infinite;
} */

.go-see {
  display: flex;
  /* width: 50px; */
  /* height: 50px; */
  border-radius: 25px;
  right: 15px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: #fff;
  text-decoration: none;
  color: #000;
  transition: 0.3s;
  padding: 10px 30px;
}
.go-see:hover{
  color: #000;
}

.btn-delay {
  animation: slide-slow 1s linear infinite;
  border-radius: 100px;
  background: #f5b320;
}

@keyframes slide-slow {

  0%, 100% {
    bottom: 7px;
  }

  50% {
    bottom: 0;
    box-shadow: 0px 12px 15px -8px rgba(245, 179, 32, 0.4)
  }
}

.active-bold{
  color: #000 !important;
}
