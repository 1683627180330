.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/* @import url("./assets/font/stylesheet.css"); */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

body,
html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  /*	font-size: 16px;*/
}

body {
  /* font-family: 'Open Sans', sans-serif !important; */
  font-family: 'Open Sans' !important;
  font-style: normal;
  font-weight: 500 !important;
  background: #f8f8f8;
  color: #242424;
  line-height: 1.3;

}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.wrapper {
  min-height: 90vh;
  overflow: hidden;
  width: 100%;
  max-width: 1920px;
}
header {
  padding: 0 0px;
  z-index: 9;
}
.font-10 {
  font-size: 10px !important;
}
.font-11 {
  font-size: 11px !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-16 {
  font-size: 16px !important;
}
.font-18 {
  font-size: 18px !important;
}
.font-20 {
  font-size: 20px !important;
}
.font-22 {
  font-size: 22px !important;
}
.font-24 {
  font-size: 24px !important;
}
.font-26 {
  font-size: 26px !important;
}
.font-28 {
  font-size: 28px !important;
}
.font-30 {
  font-size: 30px !important;
}
.font-32 {
  font-size: 32px !important;
}
.font-38 {
  font-size: 38px !important;
}
.font-42 {
  font-size: 42px !important;
}
.font-50 {
  font-size: 50px !important;
}
.font-60 {
  font-size: 60px !important;
}
.font-70 {
  font-size: 70px !important;
}
.font-80 {
  font-size: 80px !important;
}
.font-100 {
  font-size: 100px !important;
}

.weight-100 {
  font-weight: 100 !important;
}
.weight-200 {
  font-weight: 200 !important;
}
.weight-300 {
  font-weight: 300 !important;
}
.weight-400 {
  font-weight: 400 !important;
}
.weight-500 {
  font-weight: 500 !important;
}
.weight-600 {
  font-weight: 600 !important;
}
.weight-700 {
  font-weight: 700 !important;
}
.color-black{
  color: #000 !important;
}
.color-gray{
  color: #7F7F7F !important;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
