/* @import url("../font/stylesheet.css"); */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");

html {
  scroll-behavior: smooth;
}

html.w-b {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.block-effect {
  opacity: 0;
  transition: 0.7s;
  z-index: -1 !important;
}

.spinner-container {
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  width: 100%;
  z-index: 9;
  top: 0;
}

.loading-spinner {
  width: 60px;
  height: 60px;
  border: 8px solid #f3f3f3;
  /* Light grey */
  border-top: 8px solid #d8262f;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  position: absolute;
  top: 46%;
  left: 46%;
  transform: translate(-50%, -50%);
}

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #ffffff;
  /*    border: 0px none #ffffff;*/
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

::-webkit-scrollbar-thumb:active {
  background: #ffffff;
}

::-webkit-scrollbar-track {
  background: #b59e8b;
  /*    border: 0px none #ffffff;*/
  border-radius: 50px;
}

.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: #2f2f2f !important;
}

.alice-carousel__dots-item:not(.__custom) {
  background-color: #c1c1c1 !important;
  width: 30px !important;
  height: 4px !important;
  border-radius: 15px !important;
}

.banner-custom .alice-carousel__dots {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.banner-custom .alice-carousel__dots-item:not(.__custom) {
  background-color: #b3b3b3 !important;
}

.banner-custom .alice-carousel__dots-item:not(.__custom):hover,
.banner-custom .alice-carousel__dots-item:not(.__custom).__active {
  background-color: #000000 !important;
}

.alice-carousel__stage-item img {
  padding: 5px !important;
}

.alice-carousel__dots-item:not(.__custom):not(:last-child) {
  margin-right: 7px !important;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  position: absolute;
  /* width: 0% !important; */
  top: 40%;
  background: rgba(255, 255, 255, 0.7);
  width: 33px !important;
  height: 56px;
}

.alice-carousel__prev-btn {
  left: 20px;
  /* background-color: #fff; */
}

.alice-carousel__next-btn {
  right: 30px;
  /* background-color: #fff; */
}

.banner-custom .alice-carousel__stage-item img {
  padding: 0px !important;
}

.banner-custom .alice-carousel__prev-btn {
  left: 30px;
  width: 0% !important;
  background: unset;
}
.banner-custom-b .alice-carousel__prev-btn {
  left: 30px;
  width: 0% !important;
  background: unset;
}

.banner-custom .alice-carousel__next-btn {
  right: 35px;
  width: 0% !important;
  background: unset;
}
.banner-custom-b .alice-carousel__next-btn {
  right: 35px;
  width: 0% !important;
  background: unset;
}
.py-res{
  padding: 120px 0;
}
.alice-carousel__prev-btn {
  text-align: left !important;
}

.alice-carousel__next-btn {
  text-align: right !important;
}

.cursor-pointer {
  cursor: pointer;
}

.logo-home a img {
  width: 130px;
}

.sorn-left,
.sorn-right {
  cursor: pointer;
  opacity: 0.7;
  transition: 0.7s;
}
.bg-table{
  background-color: #08AF93;
}

.banner-custom .sorn-left,
.banner-custom .sorn-right {
  cursor: pointer;
  opacity: 1;
  transition: 0.7s;
  /* filter: brightness(100) invert(1); */
}

.banner-custom .sorn-left:hover,
.banner-custom .sorn-right:hover {
  opacity: 0.5;
}

.banner-custom .sorn-left {
  transform: rotate(180deg);
}

.sorn-left:hover,
.sorn-right:hover {
  opacity: 1;
}

.sorn-left {
  transform: rotate(180deg);
}

ul.menu-top {
  display: table;
}

ul.menu-top li {
  list-style: none;
  display: table-cell;
  padding: 0px 0;
}

ul.menu-top li a {
  padding: 0 20px;
  color: #7f7f7f;
  vertical-align: bottom;
  margin-bottom: 0px;
  text-decoration: none;
  font-size: 20px;
  transition: 0.3s;
}

ul.menu-top li a:hover,
ul.menu-top li a.active {
  color: #000000;
}

.dp-menu button {
  background: unset;
  box-shadow: unset;
  border: unset;
  margin-bottom: 0px;
  padding: 0 20px;
  font-weight: 500;
  vertical-align: bottom;
  color: #7f7f7f;
  font-size: 18px;
  transition: 0.3s;
}

.bg-menu-top {
  box-shadow: 0px 10px 20px 0px #00000040;
}

.dp-menu.active button,
.btn.show,
.dp-menu button:hover,
.dp-menu button:focus:not(:focus-visible),
.dp-menu .btn:active,
.btn-check:active+.btn:focus,
.btn-check:checked+.btn:focus,
.btn.active:focus,
.btn.show:focus,
.btn:active:focus {
  background: unset;
  border: unset;
  box-shadow: unset;
  color: #000000;
}

.box-cicle {
  background: rgb(0, 0, 0);
  width: 35px;
  height: 35px;
  color: rgb(255, 255, 255);
  display: inline-block;
  border-radius: 50%;
  font-size: 20px;
}

.content-custom {
  line-height: 2;
}

.content-custom p, .content-custom ul, .content p, .content ul {
  margin-bottom: 0;
  /* font-size: 18px !important; */
  font-family: 'Open Sans' !important;
}

.content-custom img, .content img {
  padding: 20px 10px 20px 0;
}

/* .content-custom img:first-child, .content img:first-child{
  padding-left: 0;
} 
.content-custom img:last-of-type, .content img:last-of-type{
  padding-right: 0 !important;
}  */

.text-justify {
  text-align: justify;
}
.bg-top-rigth{
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
}
.border-right {
  border-right: 1px solid #000;
}

.border-right:last-child {
  border: unset;
}

.dp-menu .dropdown-menu {
  padding: 25px 15px 15px;
  border-radius: 12px;
  box-shadow: 0px 10px 20px 0px #00000040;
  border: unset;
  margin-top: 15px;
}

.dp-menu .dropdown-item {
  color: #afafaf;
  font-size: 18px;
  transition: 0.7s;
  margin-bottom: 7px;
  font-weight: 500;
  text-align: center;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #000;
  background: unset;
}

.dropdown-item.active .b-text-menu,
.dropdown-item:active .b-text-menu {
  border: 2px solid #f5b320;
}

.dp-menu .dropdown-item:hover {
  color: #000;
  background: unset;
}

.dp-menu .dropdown-item:hover .b-text-menu {
  border: 2px solid #f5b320;
}

.dp-menu.show button::after {
  transform: rotate(180deg);
}

.main-style:hover .dropdown-menu {
  display: block;
}

.dropbtn {
  /* background: #04aa6d; */
  border: none;
  font-size: 17px;
  padding: 17px;
  color: #fff;
}

.dropbtn::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  transition: 0.4s;
}

.dp-menu:hover .dropbtn,
.dropbtn.active {
  color: #000;
}

.dp-menu:hover .dropbtn::after,
.dropbtn:hover:after {
  transform: rotate(180deg);
  transition: 0.4s;
}

.drobdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  min-width: 160px;
  position: absolute;
  z-index: 1;
  padding-top: 15px;

  border: unset;
}

.box-sub-menu {
  background: #fff;
  border-radius: 12px;
  padding: 25px 15px 15px;
  box-shadow: 0px 10px 20px 0px #00000040;
}

.dropdown-content a {
  display: block;
  color: #000;
  text-decoration: none;
  padding: 17px;
  font-size: 18px;
}

.dropdown-content a:hover {
  background: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.box-super-menu {
  /* display: none; */
  position: absolute;
  right: -43px;
  background: #fff;
  border-radius: 12px;
  padding: 25px 15px 15px;
  box-shadow: 0px 10px 20px 0px #00000040;
  opacity: 9999;
}

.sub-super-menu:hover .box-super-menu {
  display: block;
}

/* .dropdown:hover .dropbtn {
  background: #3e8341;
} */

.un,
.un2 {
  display: inline-block;
}

.input-custom {
  border-bottom: 1px solid #000;
  border-radius: unset;
  border-top: unset;
  border-left: unset;
  border-right: unset;
  color: #000;
}

.input-custom:focus {
  background: hsla(0, 0%, 100%, 0);
  /* color: #fff; */
}

.form-control:focus,
.form-select:focus {
  box-shadow: unset !important;
  border-color: unset;
}

.form-select,
.form-control {
  border: 1px solid #000;
}

.menu-box {
  position: absolute;
  width: 98%;
  left: 50%;
  top: -5px;
  background-color: #fff;
  transform: translate(-50%, 0);
  height: 38px;
}

.menu-box2 {
  position: absolute;
  width: 90%;
  left: 50%;
  top: -4px;
  background-color: #fff;
  transform: translate(-50%, 0);
  height: 35px;
}

.absolute-center {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
}

.absolute-center-top {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  z-index: 1;
}

.absolute-center-left {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  z-index: 1;
}

.absolute-center-right {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0%, -50%);
  z-index: 1;
}

.absolute-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.top-about-line {
  margin-top: 100px;
}

.box-slide {
  border-radius: 33px;
  background: #f5f5f5;
}

.slick-dots li {
  margin: 0 -3px !important;
}

.slick-dots {
  bottom: -50px !important;
}

.slick-dots li button:before {
  opacity: 0.07 !important;
}

.slick-dots li.slick-active button:before {
  opacity: 0.2 !important;
}

.box-go {
  border-radius: 100px;
  background: #f5b320;
  box-shadow: 0px 20px 40px 0px rgba(245, 179, 32, 0.4);
}

.go-i {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  right: 15px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: #fff;
  text-decoration: none;
  color: #000;
  transition: 0.3s;
}

.go-i:hover {
  color: #000;
  transition: 0.3s;
  box-shadow: 0px 0px 14px 6px rgba(255, 255, 255, 0.8);
}

.box-web-col {
  text-decoration: none;
  color: #000;
  transition: 0.3s;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  transition: transform 0.7s;
  display: block;
}

.box-web-col:hover {
  text-decoration: none;
  color: #000;
  transform: translateY(-3%);
  transition: transform 0.7s;
}

.box-ddd {
  padding-right: 150px !important;
}

.mt-35 {
  margin-top: -35px;
}

.box-event {
  background-color: #f5b320;
  border-radius: 30px;
  overflow: hidden;
  transition: 0.5s;
  cursor: pointer;
}

.box-event-img {
  height: 180px;
}

.box-event img {
  transition: transform 0.5s;
}

.box-event:hover {
  transform: translateY(-15px);
  box-shadow: 0px 10px 20px 0px #00000040;
}

.box-event:hover img {
  transform: scale(1.1);
}

.box-vdo {
  border-bottom: 1px solid #a2a4a6;
  margin-bottom: 45px;
}

.box-vdo:last-child {
  border-bottom: unset;
}

.img-partner {
  height: 100px;
}

.bg-top {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.bg-bottom {
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
}
.box-margin-top-lop-b{
  margin-top: -60px;
}
.bg-top-left {
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
}

.bg-top-contain {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}

.mission-1 {
  border-radius: 30px;
  background: linear-gradient(42.41deg,
      #f7ce0e 2.31%,
      #f9d73b 18.39%,
      #fbdf61 34.48%,
      #fce57f 51.51%,
      #feea94 66.64%,
      #feeda1 82.73%,
      #ffeea6 96.92%);
  box-shadow: 0px 7px 20px 0px #fce57f;
  height: 100%;
}

.mission-2 {
  border-radius: 30px;
  background: linear-gradient(42.41deg,
      #05a2e9 2.31%,
      #12a8eb 7.99%,
      #38bbf2 26.91%,
      #56caf7 44.88%,
      #6bd4fb 62.86%,
      #78dafe 80.84%,
      #7dddff 96.92%);
  box-shadow: 0px 7px 20px 0px #56caf7;
  height: 100%;
}

.mission-3 {
  border-radius: 30px;
  background: linear-gradient(42.41deg,
      #aa52a6 2.31%,
      #c56ec3 27.85%,
      #d982d8 51.51%,
      #e58ee5 75.16%,
      #ea92ea 96.92%);
  box-shadow: 0px 7px 20px 0px #e58ee5;
  height: 100%;
}

.mission-4 {
  border-radius: 30px;
  background: linear-gradient(42.41deg,
      #7aae01 2.31%,
      #87b90f 17.45%,
      #9bc924 44.88%,
      #a7d231 71.37%,
      #abd636 96.92%);
  box-shadow: 0px 7px 20px 0px #a7d231;
  height: 100%;
}

.mission-5 {
  border-radius: 30px;
  background: linear-gradient(42.41deg,
      #f99a22 2.31%,
      #f99b24 3.26%,
      #fbab4a 23.12%,
      #fcb868 42.05%,
      #fec17d 60.97%,
      #fec78a 79.89%,
      #ffc98f 96.92%);
  box-shadow: 0px 7px 20px 0px #fcb868;
  height: 100%;
}

.box-mission {
  padding-top: 30px;
  padding-right: 30px;
}

.icon-mission {
  top: 0;
  right: 0;
  width: 100px;
}

.icon-mail {
  top: 50%;
  right: 5px;
  background-color: #f5b320;
  padding: 3px 20px 6px;
  border-radius: 8px;
  transform: translate(0%, -50%);
  width: 60px;
}

.icon-go {
  right: -50px;
  width: 200px;
}

.box-board {
  padding-top: 30px;
}

.icon-board {
  top: -40px;
  left: 50%;
  width: 150px;
  transform: translate(-50%, 0%);
}

.text-board {
  bottom: 0;
  background-color: #ffeea5;
  width: 100%;
  padding: 15px 0;
  text-align: center;
  border-radius: 0px 0 30px 30px;
}

.board-01 {
  border-radius: 30px;
  box-shadow: 0px 5px 30px -10px #00000040;
  height: 100%;
  background: #fff;
  overflow: hidden;
}

.board-01-01 {
  border-radius: 30px;
  box-shadow: 0px 5px 30px -10px #00000040;
  height: 100%;
  background: #fff;
  overflow: hidden;
}

.box-activities {
  border-radius: 30px 0px 0 30px;
  overflow: hidden;
}

.box-structor {
  display: block;
  color: #000;
  text-decoration: none;
  transition: transform 0.5s;
}

.box-structor:hover {
  transform: translateY(-15px);
  color: #000;
  /* box-shadow: 0px 10px 20px 0px #00000040; */
}

.box-margin-top {
  margin-top: -120px;
}

.rounded-30 {
  border-radius: 30px;
}

.a-null {
  text-decoration: none;
  color: #000;
  transition: 0.3s;
}

.a-null:hover {
  color: #000;
  opacity: 0.7;
}

.a-underline {
  text-decoration: none;
  color: #000;
  transition: 0.3s;
}

.a-underline:hover {
  color: #000;
  text-decoration: underline;
  transition: 0.3s;
}

.a-news {
  text-decoration: none;
  color: #000;
  transition: 0.3s;
  display: block;
  transform: translateY(0%);
  transition: transform 0.7s;
}

.a-news:hover {
  color: #000;
  transform: translateY(-3%);
  transition: transform 0.7s;
}

.topic {
  font-size: 40px;
  font-weight: 700;
}

.news-topic {
  font-size: 22px;
  font-weight: 700;
}

.event-topic {
  font-size: 26px;
  font-weight: 700;
}

.sub-topic {
  font-size: 32px;
  font-weight: 700;
}

.view-tag {
  background-color: #d9d9d9;
  padding: 2px 15px;
  font-size: 12px;
  border-radius: 12px;
  margin-right: 3px;
  display: inline-block;
}

.content a {
  word-wrap: break-word;
}

.box-upcoming-event {
  border-left: 20px solid #FAB547;
  padding: 20px 30px;
  border-radius: 7px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  display: block;
  transform: translateY(0%);
  transition: transform 0.7s;
  transition: 0.7s;
}

.box-upcoming-event:hover {
  transform: translateY(-3%);
  transition: transform 0.7s;
  border-left: 20px solid #ED6530;
  transition: 0.7s;
 
}

.pagination .page-link {
  border: unset;
  color: #000;
  font-weight: 400;
}

.pagination .active>.page-link,
.pagination .page-link.active,
.page-link:hover {
  background-color: unset;
  color: #000;
  font-weight: 600;
  text-decoration: underline;
}

.pagination .page-item:hover img,
.pagination .page-item.disabled img {
  opacity: 0.5;
}

.box-search {
  border-radius: 10px;
  top: 50%;
  left: 50%;
  background: #fff;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.25);
  transform: translate(-50%, -50%);
  z-index: 9;
}

.modal-gallery .modal-content {
  background-color: unset;
  border: unset;
}

.modal-gallery .modal-content .modal-header {
  border-bottom: unset;
}

.modal-gallery .modal-content .modal-body {
  border-radius: 8px;
  overflow: hidden;
}

.box-iframe {
  border-radius: 8px;
  overflow: hidden;
}

.box-play-all {
  padding: 30px 30px 60px;
  border-radius: 20px;
}

.box-decoding-list {
  margin-bottom: 90px;
  /* border-bottom: 1px solid #a4a6a8; */
  padding-bottom: 60px;
}

.box-decoding-list:last-child {
  border-bottom: unset;
}

.w-md-75 {
  width: 75% !important;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
}

.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}

.gg-box {
  height: 220px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
}

.col-md-20 {
  width: 20% !important;
}

.gg-box-b {
  height: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  margin: 5px;
}
.gg-box-c {
  height: 230px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  margin: 5px;
}
.gg-box-d {
  height: 230px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  margin: 5px;
}

.box-slide-detail .slick-dots {
  bottom: -20px !important;
}

.box-slide-detail .slick-prev:before,
.box-slide-detail .slick-next:before {
  opacity: 0;
}

.box-gallery {
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.alice-carousel__wrapper {
  background-color: #fff;
}

.box-margin-top-lop {
  margin-top: -230px;
  z-index: 1;
}

.content .table-bordered>:not(caption)>*>* {
  max-width: 0px;
}

.content .table-bordered>:not(caption)>*>*, .content .table-bordered>:not(caption)>* {
  border-width: 0 0px;
}

.content .table-bordered>:not(caption)>*>* {
  padding: 0 30px;
}

.box-archives {
  border-radius: 33px;
  background: #FFF;
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.20);
}

.acc-custom {
  padding-right: 40px;
}

.acc-custom .accordion-item {
  border: unset;
  border-bottom: 1px solid #D9D9D9;
}

.acc-custom .accordion-item .accordion-button {
  padding-left: 0;
}

.acc-custom .accordion-item:last-of-type {
  border: unset !important;
}

.acc-custom .accordion-body {
  padding-left: 0;
  padding-top: 0;
}

.acc-custom .accordion-button::after {
  opacity: 0;
}

.acc-custom .accordion-item .accordion-collapse.collapse, .acc-custom .accordion-item .accordion-collapse.collapse.show {
  transition: 1s;
}

.position-md-absolute {
  position: absolute;
}

ul.partner-logo {
  padding: 0;
  margin: 0;
  display: block;
}

ul.partner-logo li {
  list-style: none;
  display: inline-block;
  padding: 0 12px 10px;
  width: 14.285%;
}

ul.partner-logo li img {
  width: 100%;
}

.section-striped:nth-of-type(odd) {
  background: #fff;
}

.section-striped {

  background: #F5F5F5
}

.bg-unset {
  background: unset !important;
}

img.note-float-left {
  margin-right: 10px;
}

.ul-concept {
  display: table;
  padding: 0;
  margin: 0;
  margin: auto 0;
  width: 100%;
}

.ul-concept li {
  list-style: none;
  display: table-cell;
  text-align: center;
  /* border: 1px solid red; */
}

.ul-concept li.concept-li-1:hover .concept-full-1,
.ul-concept li.concept-li-2:hover .concept-full-2,
.ul-concept li.concept-li-3:hover .concept-full-3,
.ul-concept li.concept-li-4:hover .concept-full-4,
.ul-concept li.concept-li-5:hover .concept-full-5,
.ul-concept li.concept-li-6:hover .concept-full-6,
.ul-concept li.concept-li-7:hover .concept-full-7,
.ul-concept li.concept-li-8:hover .concept-full-8 {
  opacity: 1;
  transition: 0.5s;
  z-index: 9;
  visibility: visible;
}

.ul-concept li .concept-full {
  /* display: none; */
  opacity: 0;
  background-color: #fff;
  /* transition: 0.5s; */
  z-index: -99;
  visibility: hidden;
}

.box-acc {
  min-width: 350px;
  right: 0;
  top: 70px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.10);
  padding-bottom: 15px;
}

ul.acc {
  padding: 0;
  margin: 0;
}

ul.acc li {
  list-style: none;
  vertical-align: middle;
  padding: 8px 12px;
  cursor: pointer;
  transition: 0.5s;
}

ul.acc li:hover, ul.f-s li:hover, ul.acc li.active, ul.f-s li.active {
  border-radius: 5px;
  background: #F5F5F5;
  transition: 0.5s;
}

ul.f-s {
  padding: 0;
  margin: 0;
}

ul.f-s li {
  list-style: none;
  display: inline-block;
  padding: 8px 12px;
  font-weight: 700;
  vertical-align: middle;
  cursor: pointer;
}

.text-plugin-eye {
  display: none;
  transition: 0.5s;
}

.box-eye:hover .text-plugin-eye, .box-eye.active .text-plugin-eye {
  display: inline-block;
  transition: 0.5s;
}

.custom-li .accordion-item {
  /* background-color: red; */
  margin-bottom: 15px;
  border: unset;
  background: unset;
}

.custom-li .accordion-item .accordion-header .accordion-button {
  background-color: #D9D9D9;
  border-radius: 25px !important;
  font-weight: 700;
  color: #000;
}

.custom-li .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background-color: #F5B31F;
  border-top-left-radius: 25px !important;
  border-top-right-radius: 25px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  font-weight: 700;
  color: #fff;
}

.custom-li .accordion-collapse.show {
  background-color: #fff;
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  box-shadow: 0 0 10px 2px #D9D9D9;
}

.custom-li .accordion-button:not(.collapsed)::after {
  filter: brightness(0) invert(1);
}

.custom-li ul {
  padding: 0;
  margin: 0;
}

.custom-li ul li {
  list-style: none;
  border-bottom: 1px solid #D9D9D9;
  padding: 10px 0;
}
.custom-li ul li:last-child {
  border-bottom: unset;

}
.custom-li ul li a {
  border: 1px solid #AFAFAF;
  padding: 3px 15px;
  border-radius: 5px;
  text-decoration: none;
  color: #000;
}
.maker-ol li.maker-ol-li::marker{
  font-weight:700;
  font-size: 18px;
  }
  /* .maker-ol li:before {
    content: ")";
  } */

  body{margin-top:20px;}
.timeline-steps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap
}

.timeline-steps .timeline-step {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;   
}
.btn-outline-passtel{
  border-radius: 5px;
  border: 1px solid #FEDE58;
}
.btn-outline-passtel:hover{
  border: 1px solid #FEDE58;
}

@media (min-width:768px) {
    .timeline-steps .timeline-step:not(:last-child):after {
        content: "";
        display: block;
        border-top: .1rem solid #FEDE58;
        width: 5.56rem;
        position: absolute;
        left: 5.5rem;
        top: .45rem
    }
    .timeline-steps .timeline-step:not(:first-child):before {
        content: "";
        display: block;
        border-top: .1rem solid #FEDE58;
        width: 5.56rem;
        position: absolute;
        right: 5.5rem;
        top: .45rem
    }
   
}

.timeline-steps .timeline-content {
    width: 10rem;
    text-align: center
}

.timeline-steps .timeline-content .inner-circle {
    border-radius: 1.5rem;
    height: 1rem;
    width: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #FEDE58
}

.timeline-steps .timeline-content .inner-circle:before {
    content: "";
    /* background-color: #3b82f6; */
    display: inline-block;
    height: 3rem;
    width: 3rem;
    min-width: 3rem;
    border-radius: 6.25rem;
    opacity: .5
}