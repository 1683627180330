/* @import url(https://fonts.googleapis.com/css?family=Raleway:400, 700, 800);
*, */

*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #7f7f7f;
  /* color: #f5b320; */
}


html {
  height: 100%;
}

#app {
  height: 100%;
}

main {
  height: 100%;
  padding: 3em 2em;
  text-align: center;
  background: #b4bad2;
  overflow: auto;
}

.menu-res {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  background: #fff;
  box-shadow: 0px 10px 20px 0px #00000040;
  /* height: 70px; */
}

.bm-overlay {
  top: 0;
}

.description {
  max-width: 20em;
  margin: 1.2em auto 1em;
  line-height: 1.3em;
  font-weight: 400;
}

.demo-buttons {
  font-size: 1em;
  max-width: 1200px;
  margin: 2em auto 3em;
  padding: 0 10em;
}

.demo-buttons a {
  display: inline-block;
  margin: 0.75em;
  padding: 1.35em 1.1em;
  width: 15em;
  background: #fffce1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 800;
  border-top-left-radius: 20px 50px;
  border-top-right-radius: 20px 50px;
  border-bottom-right-radius: 20px 50px;
  border-bottom-left-radius: 20px 50px;
  cursor: pointer;
}

.demo-buttons a.current-demo {
  background: #c94e50;
  color: #fffce1;
}

.side-button {
  display: inline-block;
  width: 5em;
  height: 2.5em;
  line-height: 2.5em;
  cursor: pointer;
  background: #fffce1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8em;
  font-weight: 800;
}

.side-button.left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.side-button.right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.side-button.active {
  background: #c94e50;
  color: #fffce1;
}

@media screen and (max-width: 40em) {
  main {
    font-size: 80%;
  }

  .demo-buttons {
    max-width: 900px;
    padding: 0 2em;
  }
}

.bm-burger-button {
  position: fixed;
  width: 35px;
  height: 35px;
  left: 36px;
  top: 9px;
  border-radius: 3px;
}

.bm-burger-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 8px;
}

.bm-burger-button button:focus+span span.bm-burger-bars {
  background-color: #c94e50;
}

.right .bm-burger-button {
  left: initial;
  right: 10px;
  background-color: #fff;
}

.bm-burger-bars {
  background: #1c184a;
  height: 3px !important;
  z-index: 9;
  left: 7px !important;
  width: 20px;
}

.bm-burger-button span {
  display: block;
  height: 20px;
  position: relative;
  top: 8px;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-menu a {
  color: #2f2f2f;
}

.bm-menu a:hover,
.bm-menu a:focus {
  color: #c94e50;
}

.bm-item-list a {
  padding: 0.8em;
}

.bm-item-list a span {
  margin-left: 10px;
  font-weight: 700;
}

.bm-item:focus {
  outline: none;
}

.menu-1 .bm-cross {
  background: #bdc3c7;
}

.menu-1 .bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.menu-1 .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

.menu-1 .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

.menu-2 .bm-cross {
  background: #999;
}

.menu-2 .bm-menu {
  padding: 3.4em 1em 0;
}

.menu-2 a {
  padding: 1em;
}

.menu-2 i {
  font-size: 1.7em;
  vertical-align: middle;
  color: #282a35;
}

.menu-2 .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

.menu-2 .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

.menu-3 .bm-cross {
  background: #888;
}

.menu-3 .bm-menu {
  padding: 2em 1em;
  font-size: 1.15em;
}

.menu-3 i {
  opacity: 0.5;
}

.menu-3 span {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.75em;
}

.menu-3 .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

.menu-3 .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

.menu-4 .bm-cross {
  background: #888;
}

.menu-4 h2 {
  margin: 0 auto;
  padding: 2em 1em;
  color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}

.menu-4 h2 i {
  margin-left: 0.2em;
}

.menu-4 h2 span {
  font-size: 1.6em;
  font-weight: 700;
}

.menu-4 a {
  padding: 1em;
  text-transform: uppercase;
  transition: background 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}

.menu-4 a span {
  letter-spacing: 1px;
  font-weight: 400;
}

.menu-4 a:hover,
.menu-4 a:focus {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
  color: #b8b7ad;
}

.menu-4 .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

.menu-4 .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

#slide .bm-cross,
#stack .bm-cross,
#reveal .bm-cross {
  background: #bdc3c7;
}

#slide .bm-menu,
#stack .bm-menu,
#reveal .bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

#slide .bm-cross-button button:focus,
#stack .bm-cross-button button:focus,
#reveal .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

#slide .bm-cross-button button:focus+span span.bm-cross,
#stack .bm-cross-button button:focus+span span.bm-cross,
#reveal .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

#bubble .bm-cross,
#push .bm-cross {
  background: #999;
}

#bubble .bm-menu,
#push .bm-menu {
  padding: 3.4em 1em 0;
}

#bubble a,
#push a {
  padding: 1em;
}

#bubble i,
#push i {
  font-size: 1.7em;
  vertical-align: middle;
  color: #282a35;
}

#bubble .bm-cross-button button:focus,
#push .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

#bubble .bm-cross-button button:focus+span span.bm-cross,
#push .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

#elastic .bm-cross {
  background: #888;
}

#elastic .bm-menu {
  padding: 2em 1em;
  font-size: 1.15em;
}

#elastic i {
  opacity: 0.5;
}

#elastic span {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.75em;
}

#elastic .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

#elastic .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

#scaleDown .bm-cross,
#scaleRotate .bm-cross,
#pushRotate .bm-cross,
#fallDown .bm-cross {
  background: #888;
}

#scaleDown h2,
#scaleRotate h2,
#pushRotate h2,
#fallDown h2 {
  margin: 0 auto;
  padding: 2em 1em;
  color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}

#scaleDown h2 i,
#scaleRotate h2 i,
#pushRotate h2 i,
#fallDown h2 i {
  margin-left: 0.2em;
}

#scaleDown h2 span,
#scaleRotate h2 span,
#pushRotate h2 span,
#fallDown h2 span {
  font-size: 1.6em;
  font-weight: 700;
}

#scaleDown a,
#scaleRotate a,
#pushRotate a,
#fallDown a {
  padding: 1em;
  text-transform: uppercase;
  transition: background 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}

#scaleDown a span,
#scaleRotate a span,
#pushRotate a span,
#fallDown a span {
  letter-spacing: 1px;
  font-weight: 400;
}

#scaleDown a:hover,
#scaleRotate a:hover,
#pushRotate a:hover,
#fallDown a:hover,
#scaleDown a:focus,
#scaleRotate a:focus,
#pushRotate a:focus,
#fallDown a:focus {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
  color: #b8b7ad;
}

#scaleDown .bm-cross-button button:focus,
#scaleRotate .bm-cross-button button:focus,
#pushRotate .bm-cross-button button:focus,
#fallDown .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

#scaleDown .bm-cross-button button:focus+span span.bm-cross,
#scaleRotate .bm-cross-button button:focus+span span.bm-cross,
#pushRotate .bm-cross-button button:focus+span span.bm-cross,
#fallDown .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

ul.menu-m {
  padding: 20px 15px 20px 15px;
  margin: 0;
}

ul.menu-m li {
  list-style: none;
  text-align: right;
  padding: 5px 0px 5px 0px;
  border-bottom: 1px solid #e4e2dd;
}

ul.menu-m li a {
  text-decoration: none;
  font-size: 14px;
  padding: 5px 0;
  color: #797979;
  /* font-family: "Bodoni MT"; */
}

ul.menu-m li a:hover {
  color: #000;
}

ul.menu-m li:last-child {
  border-bottom: unset;
}

.bg-menu-m {
  /* background-image: url('../img/bg-menu.jpg'); */
  background-color: #fff;
  top: 0;
}

.menu-open .submenu {
  display: block;
}

.menu-openB .submenuB {
  display: block;
}

.menu-open ul.submenu li {
  border-bottom: unset;
}

.menu-open .nav-link .arrow90 {
  transform: rotate(270deg);
}

.arrow90 {
  transform: rotate(90deg);
}

.has-submenuB .nav-link .arrow90 {
  transform: rotate(90deg);
}

.menu-openB .nav-link .arrow90 {
  transform: rotate(270deg);
}

/* #menubar {
  width: 100%;
  margin-left: auto;
  text-align: center;
  margin-right: auto;
  margin-top: 80px;
  height: auto;
} */

#menubar ul {
  /* text-transform: uppercase; */
  /* text-align: left; */
  display: inline;
  margin: 0;
  padding: 15px 4px 17px 0;
  list-style: none;
  /* -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15); */
}

#menubar ul li {
  /* font: bold 12px/18px sans-serif; */
  display: inline-block;
  /* margin-right: -5px; */
  position: relative;
  /* padding: 15px 20px; */
  /* background: #f7f7f7; */
  /* color: #6aa25d; */
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  padding: 0 9px;
  font-size: 18px;
  color: #7f7f7f;
  font-weight: 600;
}

#menubar ul li.true-drop {
  background: initial;
  border: initial;
  box-shadow: none;
  color: #7f7f7f;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  padding: 0 20px;
  transition: 0.3s;
  vertical-align: bottom;
}

#menubar ul li.true-drop.active {
  color: #000;
}

#menubar ul li.true-drop::after {
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0.3em solid;
  content: "";
  display: inline-block;
  margin-left: 0.255em;
  transition: 0.4s;
  vertical-align: 0.255em;
  color: #7f7f7f;
}

#menubar ul li:hover {
  /* background: #6aa25d; */
  color: #000;
}

#menubar ul li.true-drop:hover:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: 0.4s;
  color: #f5b320;
}

#menubar ul a {
  color: #7f7f7f;

  margin-bottom: 0;
  /* padding: 0 20px; */
  text-decoration: none;
  transition: 0.3s;
  vertical-align: bottom;
}

#menubar ul a.active li {
  color: #000;
}

/*submenu css starting-------------*/
#menubar li ul {
  display: block;
  /* background-color: #f7f7f7; */
  position: absolute;
  /* margin-top: 15px; */
  margin-left: -60px;
  min-width: 250px;
  transition: opacity 1s ease-out;
  opacity: 0;
  height: 0;
  padding: 0px 0px 0px 0px;
  transition: 1s;
  visibility: hidden;
}

#menubar li ul .box-sub {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 10px 20px 0 #00000040;
  padding: 25px 12px 15px;
  margin-top: 4px;
  width: max-content;
  min-width: 250px;
  display: none;
}

#menubar li ul .box-sub a {
  margin-bottom: 7px;
  display: block;
}

#menubar li ul .box-sub a li {
  font-weight: 400;
  color: #000;
}

#menubar li ul .box-sub a.a-box-sub:hover .b-text-menu,
#menubar li ul .box-sub a.a-box-sub.active .b-text-menu {
  border: 2px solid #f5b320;
  color: #000;
  font-weight: 600;
  /* transition: .3s; */
}

#menubar li ul .box-sub a.a-box-sub:hover .sssub::after,
#menubar li ul .box-sub a.a-box-sub.active .sssub::after {
  /* color: #fff; */
  opacity: 0;
}

#menubar li ul a li {
  /* width: 8em; */
  /* border-bottom: 1px solid #e7e7e7; */
  /* width: max-content; */
  width: 100%;
}

#menubar li:hover ul {
  /* display: block; */
  opacity: 1;
  height: auto;
  padding: 15px 4px 17px 0;
  transition: 1s;
  visibility: visible;
}

#menubar li:hover ul .box-sub {
  display: block;
}

/* #menubar li ul a li:hover {
  min-width: 160px;
} */

/*----------------------------------*/
#menubar li ul li ul li {
  display: none;
  margin-left: 0px;
  position: relative;
}

#menubar ul li ul .box-sub a li ul.super-sub {
  /* display: none; */
  display: block;
  /* transition: opacity 1s ease-in-out; */
  animation: fadeOut 1s;
  opacity: 0;
  /* height: 0; */
  padding: 0px 0px 0px 10px;
  transition: 1s;
  /* width: 0; */
  visibility: hidden;
}

#menubar ul li ul .box-sub a li:hover ul.super-sub {
  /* display: block; */
  height: auto;
  opacity: 1;
  transition: 1s;
  padding: 0px 4px 17px 15px;
  visibility: visible;
}

#menubar li ul li:hover ul li {
  display: block;
}

#menubar ul ul ul {
  left: 100%;
  top: 0;
  margin-left: 0px;
  padding-left: 6%;
  padding-top: 0;
}

#menubar ul ul ul .box-super-sub {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 10px 20px 0 #00000040;
  padding: 0;
  /* transition: 1s; */
  /* width: 0; */
  width: max-content;
  animation: fadeOut 1s;
}

#menubar ul ul ul .box-super-sub a li:hover {
  font-weight: 600;
}

#menubar ul li ul .box-sub a li:hover ul.super-sub .box-super-sub {
  /* display: block; */
  /* transition: 1s; */
  padding: 25px 15px 15px;
  /* animation: fadeOut 1s; */
  /* width: max-content; */
}

#menubar ul ul ul .box-super-sub a li {
  text-align: center;
  /* width: 100%; */
  margin: 0 auto;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  99% {
    opacity: 0.01;
    width: 100%;
    height: 100%;
  }

  100% {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  99% {
    opacity: 0.01;
    width: 100%;
    height: 100%;
  }

  100% {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.sssub::after {
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0.3em solid;
  content: "";
  display: inline-block;
  margin-left: 0.255em;
  transition: 0.4s;
  vertical-align: 0.255em;
  transform: rotate(270deg);
  float: right;
  margin-top: 12px;
  color: #f5b320;
}

.menu-about-left {
  position: fixed;
  z-index: 2;
  height: 100%;
  background: rgb(0, 0, 0);
  /* background: linear-gradient(90deg, rgba(0,0,0,0.6867121848739496) 0%, rgba(0,0,0,0) 75%); */
  background: linear-gradient(91deg, rgba(0, 0, 0, 0.50) 0.59%, rgba(0, 0, 0, 0) 94%);
  display: flex;
  justify-content: center;
  top: 0;
  transition: 0.7s;
}

.menu-about-left ul {
  margin: auto 0;
  padding: 80px 20px 0 15px;

  /* position: absolute;
  top: 50%;
  transform: translate(0, 50%); */
}

.menu-about-left ul li {
  list-style: none;
  border-bottom: 1px solid #fff;
  cursor: pointer;
  color: #fff;
  transition: 0.4s;
  margin-bottom: 15px;
}

.menu-about-left ul li:last-child {
  border-bottom: unset;
}

.menu-about-left ul li:hover {
  padding-left: 10px;
  transition: 0.4s;
}

.submenu {
  display: none;
  transition: 0.4s;
}

.companymenuli {
  position: relative;
  list-style: none;
  margin: 0.1rem 0;
  width: max-content;
  display: block;
}

.companymenuli a.alisting {
  color: #7f7f7f;
  transition: 0.5s;
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
}
.companymenuli a.alisting:hover,.companymenuli a.alisting.active{
  color: #000;
}

.companymenuli:hover .submenu {
  width: 100%;
  display: block;
  transition: 0.4s;
  position: absolute;
  left: 100%;
  height: auto;
  opacity: 1;
  transition: 1s;
  padding: 15px;
  z-index: 9;
  top: -15px;
}

.submenu ul {
  padding: 15px 15px 15px 15px;
  visibility: visible;
  box-shadow: 0 10px 20px 0 #00000040;
  background-color: #fff;
  border-radius: 10px;
  width: max-content;
  z-index: 9;
}

.submenu ul li {
  list-style: none;
}

.submenu ul li a {
  cursor: pointer;
  text-decoration: none;
  color: #7f7f7f;
  font-weight: 400;
}

.submenu ul li a:hover {
  color: #000;
}