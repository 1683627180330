
$text-color: #F5B320;
$text-white: #fff;
$bg-black: #000;
html.y-b{
  #menubar ul a.active li ,#menubar ul li.true-drop::after,#menubar li ul .box-sub a li,#menubar li ul .box-sub a.a-box-sub:hover .b-text-menu, #menubar li ul .box-sub a.a-box-sub.active .b-text-menu{
      color: $text-color;
    }
    #menubar ul li.true-drop{
      color: $text-color;
    }
    .text-pi{ 
      color:$text-color !important
    }
    .box-upcoming-event{
      // color: $text-color;
      border-left:20px solid $text-color;
      // filter: grayscale(100%);
      // -webkit-filter: grayscale(100%);
      background-color: $bg-black !important;
    }

    #menubar ul li{
      color: $text-color;
    }

    .bg-white,.section-striped,button,.submenu ul{
        background-color: $bg-black !important;
      box-shadow: unset;  animation: unset;  
    }
    .board-01,.box-web-col{
      background-color: $bg-black !important;
    }
    .board-01-01{
      background-color: $bg-black !important;      
    }
    .board-01 div,.text-board,.box-web-col div{
      // color: #000 !important;
    }
    .btn-stan{
      background-color: unset !important;
    }
    img,.video-container iframe,.gg-box,.gg-box-b,.box-gallery,iframe{
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
    }
    p,div,a,a:hover,p span,.yellow-yellow{
      color: $text-color !important;
    }
    .yellow-opacity{
      opacity: 1 !important;
    }
    #menubar li ul .box-sub,.menu-box,.box-super-sub,.box-add-header,.box-acc,.box-footer{
      background-color: $bg-black !important;
    }
    .btn-wedo,.btn-about,.btn-blue{
      background-color: $bg-black !important;
      border: 1px solid $text-color !important;
      color: $text-color !important;
    }
    .yellow-year{
      background-color: $bg-black !important;
      color: $text-color !important;
    }
    .btn-search-box,.btn-register{
      color: $bg-black !important;
      border: 1px solid $text-color !important;
      background-color: $text-color !important;
    }
    .box-add-header img,.yellow-img{
      filter: invert(66%) sepia(67%) saturate(548%) hue-rotate(358deg) brightness(110%) contrast(92%) !important;
    }
    .box-acc{
      border: 1px solid $text-color !important;
    }
    .box-add-header{
      border-bottom: 1px solid $text-color !important;
    }
    .menu-about-left ul li{
      border-bottom: 1px solid $bg-black !important;
    }
    .yellow-black,.yellow-black p,.yellow-black a{
      color: $bg-black !important;
    }
    .mission-1,.mission-2,.mission-3,.mission-4,.mission-5{
      background: $bg-black !important;
      box-shadow: unset;
    }
    .yellow-box-margin-top-lop{
      margin-top: 60px !important;
    }
    .yellow-bg-white{
      background-color: #fff !important;
    }

    @media screen and (max-width: 490px){
      .menu-about-left ul li {
          border-bottom: unset !important;
      }
    }
}

body.big{
  font-size: 20px;

  #menubar ul li{
    font-size: 20px;
  }
  .topic{
    font-size: 42px;
  }
  .font-100 {
    font-size: 102px !important;
  }
  .font-50 {
    font-size: 52px !important;
  }
  .font-24 {
    font-size: 26px !important;
}
  .font-20 {
    font-size: 22px !important;
}
  .font-18 {
    font-size: 20px !important;
  }
    .font-16 {
      font-size: 18px !important;
  }
  .font-14 {
    font-size: 16px !important;
}
  .news-topic {
    font-size: 24px;
  }
  a{
    font-size: 18px;
  }
  .sub-topic {
    font-size: 34px;
}
.btn-wedo{
  font-size: 24px;
}

}


body.superbig{
  font-size: 22px;

  #menubar ul li{
    font-size: 22px;
  }
  .topic{
    font-size: 44px;
  }
  .font-100 {
    font-size: 104px !important;
  }
  .font-50 {
    font-size: 54px !important;
  }
  .font-24 {
    font-size: 28px !important;
}
  .font-20 {
    font-size: 24px !important;
}
  .font-18 {
    font-size: 22px !important;
  }
    .font-16 {
      font-size: 20px !important;
  }
  .font-14 {
    font-size: 18px !important;
}
  .news-topic {
    font-size: 26px;
  }
  a{
    font-size: 20px;
  }
  .sub-topic {
    font-size: 36px;
}
.btn-wedo{
  font-size: 26px;
}
}